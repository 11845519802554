import React, { Component } from 'react';
import Navbar from './components/layouts/Navbar';
import LayoutProject from './components/layouts/LayoutProject';
import LayoutProjects from './components/layouts/LayoutProjects';
import LayoutTeachings from './components/layouts/LayoutTeachings';
import LayoutTeaching from './components/layouts/LayoutTeaching';
import LayoutAbout from './components/layouts/LayoutAbout';
import LayoutFooter from './components/layouts/LayoutFooter';
import ErrorPage from './components/notifications/ErrorPage';
import ScrollToTop from './components/utility/ScrollToTop';
import { AppProvider } from './AppContext';
import history from './components/utility/history';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import LoadingPage from './components/utility/LoadingPage';

class App extends Component {
  render() {
    return (
      <AppProvider>
        <LoadingPage />
        <Router basename={process.env.PUBLIC_URL} history={history}>
          <ScrollToTop />
          <React.Fragment>
            <Navbar />

            <Switch>
              <Route exact path='/'>
                <Redirect to='/design/all' />
              </Route>
              <Route exact path='/' component={LayoutProjects} />
              <Route exact path='/design/all' component={LayoutProjects} />
              <Route
                exact
                path='/design/all/:search/:tags'
                component={LayoutProjects}
              />
              <Route exact path='/design/:p_id' component={LayoutProject} />
              <Route
                exact
                path='/design/:p_id/:source'
                component={LayoutProject}
              />
              <Route exact path='/teaching/all' component={LayoutTeachings} />
              <Route exact path='/teaching/:p_id' component={LayoutTeaching} />
              <Route
                exact
                path='/teaching/:p_id/:source'
                component={LayoutTeaching}
              />
              <Route exact path='/about' component={LayoutAbout} />
              <Route component={ErrorPage} />
            </Switch>

            <LayoutFooter />
          </React.Fragment>
        </Router>
      </AppProvider>
    );
  }
}

export default App;
