import React, { Component } from 'react';
import AppContext from '../../AppContext';

import { withRouter } from 'react-router-dom';

class ProjectItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageLoading: true,
      moved: '',
      isLoaded: false,
      touch: false,
    };
  }

  static contextType = AppContext;

  handleImage = () => {
    this.setState({ imageLoading: false });
  };

  handleTouchStart = () => {
    this.setState({ touch: true });
  };

  handleTouchEnd = () => {
    this.setState({ touch: false });
  };

  componentDidMount() {
    this.setState({ isLoaded: true });
  }

  handleClick = (url) => {
    this.props.history.push(url);
  };

  render() {
    const { id, image, summary } = this.props.data;

    const { en, ar } = summary;

    return (
      <div
        onContextMenu={(e) => e.preventDefault()}
        onClick={this.handleClick.bind(this, `${this.props.route}/${id}`)}
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
        onMouseEnter={this.handleTouchStart}
        onMouseLeave={this.handleTouchEnd}
      >
        <div
          className={`project-content ${
            this.state.imageLoading && 'az-display-none'
          } ${this.state.touch ? 'move-content' : ''}`}
        >
          <div className='english'>
            <h2 dangerouslySetInnerHTML={{ __html: en.title }} />
            <p dangerouslySetInnerHTML={{ __html: en.client }} />
          </div>
          <div className='arabic'>
            <h2 dangerouslySetInnerHTML={{ __html: ar.title }} />
            <p dangerouslySetInnerHTML={{ __html: ar.client }} />
          </div>
        </div>
        <div
          className={`project-image ${this.state.touch ? 'move-image' : ''}`}
        >
          <img
            className={`az-image-container az-image-hide ${
              this.state.imageLoading ? '' : 'az-image-show'
            }`}
            src={`${process.env.PUBLIC_URL}/assets/media${this.props.route}/${image}`}
            alt=''
            onLoad={this.handleImage}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(ProjectItem);
