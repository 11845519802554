import React, { Component } from 'react';
import AppContext from '../../AppContext';

class LoadingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = AppContext;
  render() {
    return (
      !this.context.isLoadedProjects &&
      !this.context.isLoadedTeachings &&
      !this.context.isLoadedUI && (
        <div className='uk-width-1-1 uk-height-viewport az-color-muted uk-align-center uk-flex uk-flex-center az-overlay-fixed az-z-loading-top kr-color-limbo my-0'>
          <div
            className='uk-position-absolute uk-transform-center az-center-middle az-text-color-1'
            uk-spinner='ratio: 2'
          ></div>
        </div>
      )
    );
  }
}

export default LoadingPage;
