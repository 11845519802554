import React, { Component } from 'react';
import AppContext from '../../AppContext';

import { Link } from 'react-router-dom';
import NavbarSocial from './NavbarSocial';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchOpen: false,
      activeLink: 'design',
    };
  }

  static contextType = AppContext;

  handleSearch = () => {
    const newSearch = !this.state.searchOpen;
    this.setState({ searchOpen: newSearch });
  };
  handleClick = (menuItem) => {
    this.setState({ active: menuItem });
  };

  handleActiveLink = (menuItem) => {
    this.setState({ activeLink: menuItem });
  };

  render() {
    let navbarJSX = '';
    if (this.context.ui) {
      let { menuMain, menuSocial } = this.context.ui;

      navbarJSX = (
        <div className='az-color-muted'>
          <div
            id='navbarTop'
            className='az-container uk-flex uk-flex-between@s uk-flex-wrap uk-flex-center uk-flex-middle menu-main'
          >
            <div className='uk-width-expand'>
              <div
                id='navbarTop-left'
                className='uk-flex uk-flex-center uk-flex-wrap uk-width-1-1 uk-flex-left@s az-menu az-menu-item'
              >
                <div className='az-menu-item uk-hidden@s'>
                  <Link
                    onClick={this.handleActiveLink.bind(this, 'design')}
                    to={menuMain.logoMobile.url}
                    className={`az-logo ${
                      this.state.activeLink === 'design' ? 'az-active' : ''
                    }`}
                  >
                    <span>{menuMain.logoMobile.title}</span>
                  </Link>
                </div>
                <div className='az-menu-item uk-visible@s'>
                  <Link
                    onClick={this.handleActiveLink.bind(this, 'design')}
                    to={menuMain.logoDesktop.url}
                    className={`az-logo ${
                      this.state.activeLink === 'design' ? 'az-active' : ''
                    }`}
                  >
                    <span>{menuMain.logoDesktop.title.en}</span>
                    <span>{menuMain.logoDesktop.title.ar}</span>
                  </Link>
                </div>
                <div className='az-menu-item'>
                  <Link
                    onClick={this.handleActiveLink.bind(this, 'design')}
                    to={this.context.getUrl('azProjects')}
                    className={
                      this.state.activeLink === 'design' ? 'az-active' : ''
                    }
                  >
                    <span>{menuMain.design.title.en}</span>
                    <span>{menuMain.design.title.ar}</span>
                  </Link>
                </div>
                <div className='az-menu-item'>
                  <Link
                    onClick={this.handleActiveLink.bind(this, 'teaching')}
                    to={this.context.getUrl('azTeachings')}
                    className={
                      this.state.activeLink === 'teaching' ? 'az-active' : ''
                    }
                  >
                    <span>{menuMain.teaching.title.en}</span>
                    <span>{menuMain.teaching.title.ar}</span>
                  </Link>
                </div>
                <div className='az-menu-item'>
                  <Link
                    onClick={this.handleActiveLink.bind(this, 'about')}
                    to={menuMain.about.url}
                    className={
                      this.state.activeLink === 'about' ? 'az-active' : ''
                    }
                  >
                    <span>{menuMain.about.title.en}</span>
                    <span>{menuMain.about.title.ar}</span>
                  </Link>
                </div>
                <div className=''>
                  <a
                    href={menuMain.articles.url}
                    target={menuMain.articles.target}
                    className={this.state.activeLink === 'blog' ? '' : ''}
                  >
                    <span>{menuMain.articles.title.en}</span>
                    <span>{menuMain.articles.title.ar}</span>
                  </a>
                </div>
              </div>
            </div>

            <div className='uk-visible@s uk-width-auto'>
              <div
                id='navbarTop-right'
                className='uk-flex uk-flex-right uk-flex-wrap uk-width-1-1 az-menu-social'
              >
                <div className=''>
                  <a
                    href={menuSocial.instagram.url}
                    target={menuSocial.instagram.target}
                  >
                    {menuSocial.instagram.title}
                  </a>
                </div>
                <div className=''>
                  <a
                    href={menuSocial.twitter.url}
                    target={menuSocial.twitter.target}
                  >
                    {menuSocial.twitter.title}
                  </a>
                </div>
                <div className=''>
                  <a
                    href={menuSocial.linkedin.url}
                    target={menuSocial.linkedin.target}
                  >
                    {menuSocial.linkedin.title}
                  </a>
                </div>
                <div className=''>
                  <a href={menuSocial.mail.url} target={menuSocial.mail.target}>
                    {menuSocial.mail.title}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    // const { azData, setAzData } = this.context;

    return (
      <>
        <NavbarSocial />
        {navbarJSX}
      </>
    );
  }
}

export default Navbar;
