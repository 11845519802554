import React, { Component } from 'react';

class FilterElement extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    const { tag, isSelected, section } = this.props;

    let isActive = tag.tagFiltered === 0 ? 'az-filter-inactive' : '';

    return (
      <li className={`az-filter-menu ${isSelected || ''} ${isActive}`}>
        <div className='uk-clearfix'>
          <div className='uk-float-right '>
            {tag.tagFiltered === 0 ? '' : tag.tagFiltered}
          </div>
          <div className='uk-float-left az-filter-menu-width'>
            <a
              onClick={this.props.handleFilter.bind(this, section, tag.tagName)}
            >
              {tag.tagText}
            </a>
          </div>
        </div>
      </li>
    );
  }
}
export default FilterElement;
