import React, { Component } from 'react';
import AppContext from '../../AppContext';

class NavbarBottom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollPos: 0,
      isUp: false,
      lastScrollTop: 0,
      clientHeight: window.innerHeight,
      overFour: false,
    };
  }

  static contextType = AppContext;

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
    window.addEventListener('resize', this.listenToResize);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  listenToResize = () => {
    this.setState({ clientHeight: window.innerHeight });
    let vh = this.state.clientHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  listenToScroll = () => {
    const st = document.body.scrollTop || document.documentElement.scrollTop;
    // console.log('ScrollHeight', document.documentElement.scrollHeight);
    // console.log('ScrollPos', st);
    if (document.documentElement.scrollHeight > window.innerHeight * 4)
      this.setState({ overFour: true });
    // let vh = thi.state.clientHeight* 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    // document.documentElement.style.setProperty('--vh', `${vh}px`);
    if (st > this.state.lastScrollTop) {
      this.setState({
        isUp: false,
      });
    } else {
      this.setState({
        isUp: true,
      });
    }
    let lastScrollTop = st <= -100 ? 0 : st;

    this.setState({
      scrollPos: st,
      lastScrollTop,
    });
  };

  render() {
    let navPos = this.props.offset + ' az-color-scroll-top';

    let scrollHeightShow = window.innerHeight * 1.5;
    let scrollHeightHide =
      document.documentElement.scrollHeight - 2 * window.innerHeight;
    let scrollPos = this.state.scrollPos;

    if (!this.state.overFour) {
      scrollHeightShow = 4 * window.innerHeight;
      scrollHeightHide = 8 * window.innerHeight;
    }

    let isNavOn,
      floatArrow = '';
    if (this.props.isFloating) {
      isNavOn =
        this.state.isUp &&
        scrollPos > scrollHeightShow &&
        scrollPos < scrollHeightHide
          ? `az-sticky-nav-bottom-on ${this.props.offset}`
          : '';
      navPos = 'az-sticky-nav-bottom-off';
      floatArrow = 'az-arrows-float';
    }

    return (
      <div
        className={`uk-flex uk-width-1-1 uk-flex-center ${isNavOn} ${navPos} `}
      >
        <a
          className={`az-text-color-light uk-text-center az-arrows ${floatArrow} `}
          href='#'
          uk-scroll=''
        >
          {this.context.ui ? this.context.ui.nav.scrollTop : '↑'}
        </a>
      </div>
    );
  }
}

export default NavbarBottom;
