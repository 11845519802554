import React, { Component } from 'react';
import AppContext from '../../AppContext';
import Filter from '../blocks/Filter';
import ProjectListItem from '../lists/ProjectItem';
import NavbarBottom from './NavbarBottom';
import queryString from 'query-string';

import { Helmet } from 'react-helmet';

class LayoutTeachings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      shouldNavbar: false,
      search: '',
      timerOn: false,
      fetchLoaded: false,
    };
    this.bigWrapper = React.createRef();
  }

  static contextType = AppContext;

  handleChange = (e) => {
    this.setState({ search: e.target.value });
    if (!this.state.timerOn) {
      this.setState({ timerOn: true });
      setTimeout(() => {
        this.context.setSearch('azTeachings', this.state.search, true);
        this.setState({ timerOn: false });
      }, 1000);
    }
  };

  handleClear = () => {
    this.setState({ search: '' });
    this.context.setSearch('azTeachings', '', true);
  };

  getParams = () => {
    const parsed = queryString.parse(this.props.location.search, {
      arrayFormat: 'comma',
    });

    if (Object.keys(parsed).length > 0) {
      return parsed;
    } else return false;
  };

  setUrl = (parsed) => {
    this.context.setUrl(parsed, 'azTeachings');
  };

  setSearch = (parsed) => {
    if (parsed) {
      if (parsed.search) this.setState({ search: parsed.search.join(' ') });
    }
  };

  setSettings = () => {
    const params = this.getParams();
    this.setUrl(params);
    this.setSearch(params);
  };

  fireOnLoaded = () => {
    if (!this.state.fetchLoaded) {
      this.setState({ fetchLoaded: true }, () => this.setSettings());
    }
  };

  componentDidMount() {
    this.context.setFilterIcon('azTeachings', true);
    this.setState({ isLoaded: true });
    this.context.filterData('azTeachings', true);
    // this.context.setNavbar(this.bigWrapper.current);
    window.onpopstate = (e) => {
      this.setSettings();
    };
  }

  render() {
    let azMeta = '';
    this.context.isLoadedTeachings && this.fireOnLoaded();

    if (this.context.isLoadedTeachings) {
      let { og } = this.context.azTeachings;

      let meta = {
        title: '',
        description: '',
        image: '',
      };

      if (og) {
        meta.title = og.title || '';
        meta.description = og.description || '';
        meta.image = og.image || '';
      }

      let baseURL = 'https://www.azalam.com';
      let metaTitle = 'azalam / عَزَلـَمْ';
      azMeta = (
        <Helmet>
          <title>{`${metaTitle} - ${meta.title}`}</title>

          <meta property='og:url' content={`${baseURL}/teaching/all`} />
          <meta property='og:title' content={meta.title} />
          <meta property='og:description' content={meta.description} />
          <meta
            property='og:image'
            content={`${
              meta.image === ''
                ? baseURL + '/assets/media/misc/defaultsocial.jpg'
                : baseURL + '/assets/media/misc/' + meta.image
            }`}
          />
        </Helmet>
      );
    }

    const {
      dataTags,
      selectedTags,

      visibleData,
    } = this.context.azTeachings;

    const { searchTeachings } = this.context;

    let filterItems,
      projectItems,
      searchItems = '';
    if (visibleData) {
      let projectSorted = visibleData;
      // let projectItems = '';
      if (projectSorted.length > 0) {
        projectSorted.sort(function (a, b) {
          return b.visible - a.visible;
        });
      }
      // map from data into JSX
      projectItems = projectSorted.map((project, index) => {
        return (
          <div
            key={project.id}
            className={`box az-image-container project-wrapper ${
              project.theme === 'black' ? 'az-color-dark' : 'az-color-light'
            } ${!project.visible && 'az-display-none'}`}
          >
            <ProjectListItem
              isVisible={project.visible}
              data={project}
              index={index}
              route='/teaching'
            />
          </div>
        );
      });

      if (searchTeachings.length > 0) {
        searchItems = (
          <div className={`az-tags`} onClick={this.handleClear}>
            {searchTeachings.map((sItem) => {
              return sItem + ' ';
            })}
          </div>
        );
      }

      filterItems = dataTags.map((tag) => {
        let isSelected = selectedTags.find((item) => item === tag.tagName);

        return (
          <div
            key={tag.tagName}
            className={`az-tags ${isSelected ? '' : 'az-display-none'}`}
            onClick={this.context.handleFilter.bind(
              this,
              'azTeachings',
              tag.tagName
            )}
          >
            {tag.tagText}
          </div>
        );
      });
    }
    return (
      <React.Fragment>
        {azMeta}
        <Filter
          section='azTeachings'
          search={this.state.search}
          handleChange={this.handleChange}
          handleClear={this.handleClear}
        />
        <div
          className={`az-color-muted uk-flex uk-flex-center uk-flex-left@s uk-flex-wrap az-container menu-main az-filter-tags-area-padding-x ${
            (selectedTags.length > 0 || searchTeachings.length > 0) &&
            'az-filter-tags-area-padding-y'
          }`}
        >
          {searchItems}
          {filterItems}
        </div>

        <div ref={this.bigWrapper} id='projects_wrapper'>
          {projectItems}
        </div>

        <NavbarBottom isFloating={true} offset='az-sticky-nav-offset-60' />
        {this.context.shouldNavbar && (
          <NavbarBottom isFloating={false} offset='az-sticky-margin-60' />
        )}
      </React.Fragment>
    );
  }
}

export default LayoutTeachings;
