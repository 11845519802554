import React, { Component } from 'react';
import AppContext from '../../AppContext';

class NavbarSocial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollPos: 0,
      isUp: false,
      lastScrollTop: 0,
    };
  }

  static contextType = AppContext;

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  listenToScroll = () => {
    const st = document.body.scrollTop || document.documentElement.scrollTop;
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    if (st > this.state.lastScrollTop) {
      this.setState({
        isUp: false,
      });
    } else {
      this.setState({
        isUp: true,
      });
    }
    let lastScrollTop = st <= 0 ? 0 : st;

    this.setState({
      scrollPos: st,
      lastScrollTop,
    });
  };

  render() {
    let navbarJSX = '';
    let navPadding = this.context.isFilterIcon
      ? ''
      : 'az-sticky-nav-bottom-animate';
    if (this.context.ui) {
      let { menuSocial } = this.context.ui;

      navbarJSX = (
        <>
          <div
            className={`az-sticky-nav-bottom-social uk-hidden@s az-color-muted uk-flex uk-flex-around uk-flex-wrap uk-width-1-1 az-menu-social-fixed ${navPadding}`}
          >
            <div>
              <a
                className='az-text-color-dark'
                href={menuSocial.instagram.url}
                target={menuSocial.instagram.target}
              >
                {menuSocial.instagram.title}
              </a>
            </div>
            <div className=''>
              <a
                className='az-text-color-dark'
                href={menuSocial.twitter.url}
                target={menuSocial.twitter.target}
              >
                {menuSocial.twitter.title}
              </a>
            </div>
            <div className=''>
              <a
                className='az-text-color-dark'
                href={menuSocial.linkedin.url}
                target={menuSocial.linkedin.target}
              >
                {menuSocial.linkedin.title}
              </a>
            </div>
            <div className=''>
              <a
                className='az-text-color-dark'
                href={menuSocial.mail.url}
                target={menuSocial.mail.target}
              >
                {menuSocial.mail.title}
              </a>
            </div>
          </div>
        </>
      );
    }
    return <>{navbarJSX}</>;
  }
}

export default NavbarSocial;
