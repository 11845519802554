import React, { Component } from 'react';
import AppContext from '../../AppContext';

import NavbarBottom from './NavbarBottom';
import NavbarSideways from './NavbarSideways';
import { gsap } from 'gsap';
import { Helmet } from 'react-helmet';

class LayoutProject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
    };
  }

  static contextType = AppContext;

  getProject = (section) => {
    const thisProject = this.context[section].data.find(
      (project) => project.project_id === this.props.match.params.p_id
    );

    return thisProject;
  };

  componentDidMount() {
    this.context.setFilterIcon('azProjects', false);
    gsap.fromTo(
      '#project_move',
      {
        y: 120,
        opacity: 0,
      },
      {
        duration: 0.5,
        y: 0,
        opacity: 1,
        delay: 0,
        stagger: 0,
        ease: 'power3',
        force3D: true,
      }
    );
  }

  componentDidUpdate() {
    let slideX;
    if (this.props.match.params.source === 'next') slideX = 120;
    if (this.props.match.params.source === 'prev') slideX = -120;
    gsap.fromTo(
      '#project_move',
      {
        x: slideX,
        opacity: 0,
      },
      {
        duration: 0.5,
        x: 0,
        opacity: 1,
        delay: 0,
        stagger: 0,
        ease: 'power3',
        force3D: true,
      }
    );
  }
  render() {
    let pr = this.context.getData('azProjects', this.props.match.params.p_id);
    let prDetails = '';
    let prImages = '';
    let azMeta = '';

    if (pr && this.context.ui) {
      let { project } = this.context.ui;
      let { en, ar, images } = pr.details;
      let { summary, og } = pr;
      let meta = {
        title: '',
        description: '',
        image: '',
      };

      if (og) {
        meta.title = og.title || '';
        meta.description = og.description || '';
        meta.image = og.image || '';
      }

      let baseURL = 'https://www.azalam.com';
      let metaTitle = 'azalam / عَزَلـَمْ';
      azMeta = (
        <Helmet>
          <title>{`${metaTitle} - ${meta.title}`}</title>

          <meta property='og:url' content={`${baseURL}/design/${pr.id}`} />
          <meta property='og:title' content={meta.title} />
          <meta property='og:description' content={meta.description} />
          <meta
            property='og:image'
            content={`${
              meta.image === ''
                ? baseURL + '/assets/media/misc/defaultsocial.jpg'
                : baseURL + '/assets/media/design/' + meta.image
            }`}
          />
        </Helmet>
      );

      prDetails = (
        <div className='az-color-dark az-relative az-design'>
          <div className='az-container uk-flex uk-flex-between uk-flex-wrap details'>
            <div className='uk-width-1-1  uk-width-1-2@s  detail-english py-5'>
              <div className='label'>{project.en.project}</div>
              <div
                className='value'
                dangerouslySetInnerHTML={{
                  __html: en.title === '' ? summary.en.title : en.title,
                }}
              />

              <div className='label'>{project.en.client}</div>
              <div
                className='value'
                dangerouslySetInnerHTML={{
                  __html: en.client === '' ? summary.en.client : en.client,
                }}
              />

              <div className='label'>{project.en.year}</div>
              <div
                className='value'
                dangerouslySetInnerHTML={{ __html: en.year }}
              />

              <div className='label'>{project.en.role}</div>
              <div
                className='value'
                dangerouslySetInnerHTML={{ __html: en.role }}
              />

              <div className='label'>{project.en.deliverables}</div>

              <div
                className='value'
                dangerouslySetInnerHTML={{ __html: en.deliverables }}
              />
            </div>
            <div className='uk-width-1-1 uk-width-1-2@s  detail-arabic py-5'>
              <div className='label'>{project.ar.project}</div>
              <div
                className='value'
                dangerouslySetInnerHTML={{
                  __html: ar.title === '' ? summary.ar.title : ar.title,
                }}
              />

              <div className='label'>{project.ar.client}</div>
              <div
                className='value'
                dangerouslySetInnerHTML={{
                  __html: ar.client === '' ? summary.ar.client : ar.client,
                }}
              />

              <div className='label'>{project.ar.year}</div>
              <div
                className='value'
                dangerouslySetInnerHTML={{ __html: ar.year }}
              />

              <div className='label'>{project.ar.role}</div>
              <div
                className='value'
                dangerouslySetInnerHTML={{ __html: ar.role }}
              />

              <div className='label'>{project.ar.deliverables}</div>

              <div
                className='value'
                dangerouslySetInnerHTML={{ __html: ar.deliverables }}
              />
            </div>
          </div>
        </div>
      );
      prImages = (
        <div id='project_images' className='projectimages'>
          {images.map((image, index) => {
            return (
              <div className='az-image-holder' key={image.url + index}>
                <img
                  uk-img=''
                  width='100%'
                  height='auto'
                  data-src={`${process.env.PUBLIC_URL}/assets/media/design/${image.url}`}
                  alt=''
                />
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <>
        {azMeta}
        <div
          id='project_details_main_wrapper'
          className='projects_wrapper az-scroll-none az-color-dark '
        >
          <div id='project_move'>
            <div id='project_details'>{prDetails}</div>
            {prImages}
          </div>
        </div>

        {/* floating scroll to top */}
        <NavbarBottom isFloating={true} offset='az-sticky-nav-offset-120' />
        {/* Fixed scroll to top */}
        <NavbarBottom isFloating={false} offset='az-sticky-margin-120' />

        <NavbarSideways
          url={this.context.getUrl('azProjects')}
          route='/design'
          animate={false}
          offset='az-sticky-nav-offset-60'
          isFloating={true}
          nextId={this.context.getSibling(
            'azProjects',
            'next',
            this.props.match.params.p_id
          )}
          prevId={this.context.getSibling(
            'azProjects',
            'prev',
            this.props.match.params.p_id
          )}
        />
      </>
    );
  }
}

export default LayoutProject;
