import React, { Component } from 'react';

class ErrorPage extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className='uk-card-body uk-flex uk-flex-column uk-flex-center py-10'>
        <div
          uk-icon={`icon: location ;ratio:2`}
          className={`az-text-color-1 uk-align-center mb-0`}
        ></div>
        <div className='uk-align-center uk-text-center'>
          <div className='mb-2 az-text-color-1 az-error-1'>404</div>
          <div className='mt-3 az-text-color-dark az-error-2'>
            Page not found
          </div>
          <div className='mb-2 az-text-color-dark az-error-2'>
            الصفحة غير موجودة
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
