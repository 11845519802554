import React, { Component } from 'react';
import { gsap } from 'gsap';
import history from './components/utility/history';

gsap.config({ nullTargetWarn: false });
const AppContext = React.createContext();

class AppProvider extends Component {
  // Context state
  constructor(props) {
    super(props);
    this.state = {
      ui: null,

      azProjects: {
        dataTags: [],
        selectedTags: [],
        data: [],
        filteredData: [],
        visibleData: [],
        shouldFilter: true,
        isLoaded: false,
      },
      azTeachings: {
        dataTags: [],
        selectedTags: [],
        data: [],
        filteredData: [],
        visibleData: [],
        shouldFilter: true,
        isLoaded: false,
      },
      azAbout: {},
      isLoading: true,
      isLoadedProjects: false,
      isLoadedTeachings: false,
      isLoadedUI: false,
      isLoadedAbout: false,
      isFilterIcon: false,
      searchProjects: [],
      searchTeachings: [],
      shouldNavbar: false,
      searchProjectsIcon: false,
      searchTeachingsIcon: false,
      setAzData: this.setAzData,
      handleFilter: this.handleFilter,
      filterData: this.filterData,
      getData: this.getData,
      getSibling: this.getSibling,
      setFilterIcon: this.setFilterIcon,
      setSearch: this.setSearch,
      iterate: this.iterate,
      setNavbar: this.setNavbar,
      setUrl: this.setUrl,
      getUrl: this.getUrl,
      pushUrl: this.pushUrl,
    };
  }

  getUrl = (section) => {
    let query = '';
    let route = '/';
    let searchTerms;
    if (section === 'azProjects') {
      route = '/design';
      searchTerms = 'searchProjects';
    }

    if (section === 'azTeachings') {
      route = '/teaching';
      searchTerms = 'searchTeachings';
    }

    if (
      this.state[section].selectedTags.length > 0 ||
      this.state[searchTerms].length > 0
    )
      query = '?';
    if (this.state[section].selectedTags.length > 0) {
      query += `tags=${this.state[section].selectedTags.join(',')}`;
    }
    if (
      this.state[section].selectedTags.length > 0 &&
      this.state[searchTerms].length > 0
    )
      query += '&';
    if (this.state[searchTerms].length > 0) {
      query += `search=${this.state[searchTerms].join(',')}`;
    }

    let url = `${route}/all${query}`;
    return url;
  };

  pushUrl = (url) => {
    history.push(`${process.env.PUBLIC_URL}${url}`);
  };

  setUrl = (parsed, section) => {
    let searchTerms;
    if (section === 'azProjects') searchTerms = 'searchProjects';
    if (section === 'azTeachings') searchTerms = 'searchTeachings';
    if (parsed) {
      if (parsed.tags) {
        if (!Array.isArray(parsed.tags)) {
          parsed.tags = [parsed.tags];
        }
      } else parsed.tags = [];

      if (parsed.search) {
        if (!Array.isArray(parsed.search)) {
          parsed.search = [parsed.search];
        }
      } else parsed.search = [];
    } else {
      parsed = {};
      parsed.tags = [];
      parsed.search = [];
    }

    let azData = { ...this.state[section] };
    azData.selectedTags = parsed.tags;
    this.setState({ [section]: azData, [searchTerms]: parsed.search }, () => {
      this.filterData(section, false);
    });
  };
  animateIn = (classes, delay, y, duration, stagger) => {
    gsap.fromTo(
      classes,
      {
        y: 0,
        opacity: 0,
      },
      {
        duration: duration || 0.5,
        y: 0,
        opacity: 1,
        delay: delay || 0.2,
        stagger: stagger || 0.02,
        ease: 'power3',
        force3D: true,
      }
    );
  };

  setNavbar = () => {
    const container = document.getElementById('projects_wrapper');
    if (container) {
      // console.log('bigwrapper height:', container.clientHeight);
      if (container.clientHeight > window.innerHeight)
        this.setState({ shouldNavbar: true });
      else this.setState({ shouldNavbar: false });
    } else this.setState({ shouldNavbar: false });
  };

  setFilterIcon = (section, toState) => {
    // let filterIcon;
    // if (section === 'azProjects') filterIcon = 'searchProjectsIcon';
    // if (section === 'azTeachings') filterIcon = 'searchTeachingsIcon';
    this.setState({ isFilterIcon: toState });
  };

  setSearch = (section, value, source) => {
    let searchTerms;
    let arrValue = [];
    if (section === 'azProjects') searchTerms = 'searchProjects';
    if (section === 'azTeachings') searchTerms = 'searchTeachings';
    if (value !== '') arrValue = value.toLowerCase().split(' ');
    this.setState({ [searchTerms]: arrValue }, () => {
      this.filterData(section, source);
      this.pushUrl(this.getUrl(section));
    });
  };

  // Method to update state via context
  setAzData = (azData, section) => {
    this.setState(
      (prevState) => ({ [section]: azData }),
      () => {
        this.filterData(section);
      }
    );
  };

  handleFilter = (section, tagName, source) => {
    const azData = this.state[section];
    const newState = { ...azData };

    if (newState.selectedTags.indexOf(tagName) === -1) {
      newState.selectedTags.push(tagName);
    } else {
      newState.selectedTags.splice(newState.selectedTags.indexOf(tagName), 1);
    }

    this.setState({ [section]: newState }, () => {
      this.filterData(section, source);
      this.pushUrl(this.getUrl(section));
    });
  };

  getSibling = (section, pos, p_id) => {
    let inc = pos === 'next' ? 1 : -1;

    const newIndex = this.state[section].data.findIndex((item) => {
      return item.id === p_id;
    });

    if (
      this.state.isLoadedProjects &&
      this.state.isLoadedTeachings &&
      this.state.isLoadedUI
    ) {
      if (newIndex + inc < 0) return false;
      if (newIndex + inc >= this.state[section].data.length) return false;

      if (
        newIndex + inc >= 0 &&
        newIndex + inc < this.state[section].data.length
      )
        return this.state[section].data[newIndex + inc].id;
    }
  };

  getData = (section, p_id) => {
    const thisData = this.state[section].data.find((item) => item.id === p_id);

    return thisData;
  };

  iterate = (obj, stringData) => {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'string') stringData += ' ' + obj[key];
      if (Array.isArray(obj[key])) stringData += ' ' + obj[key].split(' ');
      if (typeof obj[key] === 'object') {
        this.iterate(obj[key], stringData);
      }
    });

    return stringData;
  };

  stringData = (project) => {
    const extraTags = project.extraTags || [''];
    const { summary, details, tags } = project;
    let stringData =
      tags.join(' ') +
      ' ' +
      extraTags.join(' ') +
      ' ' +
      summary.en.title +
      ' ' +
      summary.en.client +
      ' ' +
      summary.ar.title +
      ' ' +
      summary.ar.client +
      ' ' +
      details.en.title +
      ' ' +
      details.en.client +
      ' ' +
      details.en.year +
      ' ' +
      (details.en.role || details.en.place) +
      ' ' +
      (details.en.deliverables || details.en.description) +
      ' ' +
      details.ar.title +
      ' ' +
      details.ar.client +
      ' ' +
      details.ar.year +
      ' ' +
      (details.ar.role || details.ar.place) +
      ' ' +
      (details.ar.deliverables || details.ar.description);

    stringData = stringData.toLowerCase().split(' ');

    return stringData;
  };

  searchTerms = (section) => {
    let searchTerms = [];
    if (section === 'azProjects') searchTerms = this.state.searchProjects;
    if (section === 'azTeachings') searchTerms = this.state.searchTeachings;
    return searchTerms;
  };

  filterData = (section, source) => {
    let searchTerms = this.searchTerms(section);
    // console.log('search terms are:', searchTerms);
    const azData = this.state[section];
    const { selectedTags } = azData;
    const newState = { ...azData };

    newState.filteredData = azData.data.filter((project) => {
      let stringData = this.stringData(project); // build string data from certain preselected fields

      let searchResult = 1;

      if (searchTerms.length > 0) {
        searchResult = stringData.find((item) => {
          return searchTerms.find((term) => {
            return item.search(term) > -1;
          });
        });

        // searchResult = stringData.search(searchTerms);
      }

      let newTags = selectedTags.filter((tag) => {
        return project.tags.includes(tag);
      });
      if (newTags.length === selectedTags.length && searchResult) return true;
      else return false;
    });

    newState.visibleData = azData.data.map((project) => {
      let stringData = this.stringData(project);

      let searchResult = 1;

      if (searchTerms.length > 0) {
        searchResult = stringData.find((item) => {
          return searchTerms.find((term) => {
            return item.search(term) > -1;
          });
        });

        // searchResult = stringData.search(searchTerms);
      }

      let newTags = selectedTags.filter((tag) => {
        return project.tags.includes(tag);
      });
      const newProject = { ...project };
      if (newTags.length === selectedTags.length && searchResult)
        newProject.visible = true;
      else newProject.visible = false;

      return newProject;
    });

    newState.dataTags.forEach((tag) => {
      tag.tagFiltered = newState.filteredData.filter((project) => {
        return project.tags.includes(tag.tagName);
      }).length;
    });

    newState.shouldFilter = false;
    newState.isLoaded = true;
    this.setState({ [section]: newState }, () => {
      this.setNavbar();

      if (source) {
        let delay = window.innerWidth <= 640 ? 0.4 : 0.05;
        this.animateIn('.box', delay, 50, 0.4);
        this.animateIn('.az-tags', delay, 10, 0.4); //with delay
      }
    });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    fetch(`${process.env.PUBLIC_URL}/assets/data/about.json`)
      .then((response) => response.json())
      .then((data) => {
        this.setState(
          {
            azAbout: data.about,
          },
          () => {
            this.setState({ isLoadedAbout: true });
          }
        );
      });

    fetch(`${process.env.PUBLIC_URL}/assets/data/uiData.json`)
      .then((response) => response.json())
      .then((data) => {
        this.setState(
          {
            ui: data.ui,
          },
          () => {
            this.setState({ isLoadedUI: true });
          }
        );
      });

    fetch(`${process.env.PUBLIC_URL}/assets/data/design.json`)
      .then((response) => response.json())
      .then((data) => {
        data.projects.selectedTags = [];
        data.projects.shouldFilter = true;
        data.projects.filteredData = [...data.projects.data];
        data.projects.visibleData = data.projects.data.map((item) => {
          let newItem = { ...item };
          newItem.visible = true;
          return newItem;
        });

        data.projects.dataTags.forEach((tag) => (tag.tagFiltered = ''));

        this.setState(
          {
            azProjects: data.projects,
          },
          () => {
            this.setState({ isLoadedProjects: true });
            this.filterData('azProjects');
          }
        );
      });

    fetch(`${process.env.PUBLIC_URL}/assets/data/teaching.json`)
      .then((response) => response.json())
      .then((data) => {
        data.teachings.selectedTags = [];
        data.teachings.shouldFilter = true;
        data.teachings.filteredData = [...data.teachings.data];
        data.teachings.visibleData = data.teachings.data.map((item) => {
          let newItem = { ...item };
          newItem.visible = true;
          return newItem;
        });

        data.teachings.dataTags.forEach((tag) => (tag.tagFiltered = ''));

        this.setState(
          {
            azTeachings: data.teachings,
          },
          () => {
            this.setState({ isLoadedTeachings: true });
            this.filterData('azTeachings');
          }
        );
      });
  };

  render() {
    const { children } = this.props;

    return (
      <AppContext.Provider value={this.state}>{children}</AppContext.Provider>
    );
  }
}

export default AppContext;

export { AppProvider };
