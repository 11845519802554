import React, { Component } from 'react';
import AppContext from '../../AppContext';

import NavbarBottom from './NavbarBottom';

import { gsap } from 'gsap';

import { Helmet } from 'react-helmet';

class LayoutAbout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
    };
  }

  static contextType = AppContext;

  componentDidMount() {
    this.context.setFilterIcon('azProjects', false);
    gsap.fromTo(
      '#about_move',
      {
        y: 120,
        opacity: 0,
      },
      {
        duration: 0.5,
        y: 0,
        opacity: 1,
        delay: 0,
        stagger: 0,
        ease: 'power3',
        force3D: true,
      }
    );
  }

  render() {
    let { azAbout } = this.context;

    let aboutJSX = '';
    let azMeta = '';

    if (this.context.isLoadedAbout && this.context.ui) {
      let { og } = azAbout;

      let meta = {
        title: '',
        description: '',
        image: '',
      };

      if (og) {
        meta.title = og.title || '';
        meta.description = og.description || '';
        meta.image = og.image || '';
      }

      let baseURL = 'https://www.azalam.com';
      let metaTitle = 'azalam / عَزَلـَمْ';
      azMeta = (
        <Helmet>
          <title>{`${metaTitle} - ${meta.title}`}</title>

          <meta property='og:url' content={`${baseURL}/about`} />
          <meta property='og:title' content={meta.title} />
          <meta property='og:description' content={meta.description} />
          <meta
            property='og:image'
            content={`${
              meta.image === ''
                ? baseURL + '/assets/media/misc/defaultsocial.jpg'
                : baseURL + '/assets/media/misc/' + meta.image
            }`}
          />
        </Helmet>
      );

      aboutJSX = azAbout.data.map((block) => {
        if (block.type === 'text') {
          return (
            <div
              key={block.id}
              className={`az-relative ${
                block.theme === 'black' ? 'az-theme-black' : 'az-theme-white'
              }`}
            >
              <div className='az-container uk-flex uk-flex-between uk-flex-wrap az-theme-block'>
                <div className='uk-width-1-1  uk-width-1-2@s mb-10 mb-0@m'>
                  <div
                    className='az-theme-en'
                    dangerouslySetInnerHTML={{
                      __html: block.content.en,
                    }}
                  />
                </div>
                <div className='uk-width-1-1 uk-width-1-2@s'>
                  <div
                    className='az-theme-ar'
                    dangerouslySetInnerHTML={{
                      __html: block.content.ar,
                    }}
                  />
                </div>
              </div>
            </div>
          );
        }
        if (block.type === 'image') {
          return (
            <div className='projectimages az-image-holder' key={block.id}>
              <img
                uk-img=''
                width='100%'
                height='auto'
                data-src={`${process.env.PUBLIC_URL}/assets/media/about/${block.url}`}
                alt=''
              />
            </div>
          );
        }
        return '';
      });
    }

    return (
      <>
        {azMeta}
        <div
          id='project_details_main_wrapper'
          className='projects_wrapper az-scroll-none az-color-dark '
        >
          <div id='about_move'>{aboutJSX}</div>
        </div>

        <NavbarBottom isFloating={true} offset='az-sticky-nav-offset-60' />
        <NavbarBottom isFloating={false} offset='az-sticky-margin-60' />
      </>
    );
  }
}

export default LayoutAbout;
