import React, { Component } from 'react';
import AppContext from '../../AppContext';
import { gsap } from 'gsap';
import { Link } from 'react-router-dom';

class NavbarSideways extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollPos: 0,
      isUp: false,
      lastScrollTop: 0,
      scrollThresh: 750,
    };
  }

  static contextType = AppContext;

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);

    gsap.from('.az-sideways', {
      duration: 0.25,
      y: '100vw',

      opacity: 0,
      delay: 0.1,

      ease: 'power3',
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  listenToScroll = () => {
    const st = document.body.scrollTop || document.documentElement.scrollTop;

    if (st > window.innerHeight * 0.5) {
      this.setState({
        isUp: true,
      });
    } else {
      this.setState({
        isUp: false,
      });
    }
  };

  render() {
    let navPos = '';
    let isNavOn = '';
    if (this.props.isFloating) {
      let classAnim = `az-sticky-nav-bottom-on ${this.props.offset || ''}`;
      if (this.props.animate) {
        isNavOn = classAnim;
        if (this.props.animate === 'desktop') {
          if (window.innerWidth > 640)
            isNavOn = this.state.isUp ? classAnim : '';
          else isNavOn = classAnim;
        }
        if (this.props.animate === 'mobile') {
          if (window.innerWidth <= 640)
            isNavOn = this.state.isUp ? classAnim : '';
          else isNavOn = classAnim;
        }
        if (this.props.animate === 'all') {
          isNavOn = this.state.isUp ? classAnim : '';
        }
      } else isNavOn = classAnim;
      navPos = 'az-sticky-nav-bottom-off';
    }

    return (
      <>
        <div
          className={`${
            this.props.classes || ''
          } uk-flex  uk-flex-center az-color-back-bar  px-2 ${isNavOn} ${navPos} az-sideways`}
        >
          <div className={`uk-width-1-1 uk-flex uk-flex-around uk-flex-wrap`}>
            <div className='uk-width-auto'>
              <Link
                className='az-text-color-light uk-text-center az-arrows-small-text'
                to={`${this.props.url}`}
              >
                {this.context.ui ? this.context.ui.nav.back : 'Back'}
              </Link>
            </div>
            <div className='uk-flex uk-flex-right'>
              <div className='uk-width-auto'>
                {this.props.prevId ? (
                  <Link
                    className='uk-flex uk-flex-middle az-text-color-light'
                    to={`${this.props.route}/${this.props.prevId}/prev`}
                  >
                    <div className='az-arrows-small'>
                      {this.context.ui ? this.context.ui.nav.prev : '→'}
                    </div>
                    <div className='uk-visible@s az-arrows-small-text'>
                      {this.context.ui ? this.context.ui.navDesktop.prev : '→'}
                    </div>
                  </Link>
                ) : (
                  <span className='az-opacity-0 az-cursor-none uk-flex uk-flex-middle'>
                    <span className='az-arrows-small'>
                      {this.context.ui ? this.context.ui.nav.prev : '→'}
                    </span>
                    <span className='uk-visible@s az-arrows-small-text'>
                      {this.context.ui ? this.context.ui.navDesktop.prev : '→'}
                    </span>
                  </span>
                )}
              </div>

              <div className='uk-width-auto'>
                {this.props.nextId ? (
                  <Link
                    className='uk-flex uk-flex-middle az-text-color-light'
                    to={`${this.props.route}/${this.props.nextId}/next`}
                  >
                    <div className='uk-visible@s az-arrows-small-text'>
                      {this.context.ui ? this.context.ui.navDesktop.next : '→'}
                    </div>
                    <div className='az-arrows-small'>
                      {this.context.ui ? this.context.ui.nav.next : '→'}
                    </div>
                  </Link>
                ) : (
                  <span className='az-opacity-0 az-cursor-none uk-flex uk-flex-middle'>
                    <span className='uk-visible@s az-arrows-small-text'>
                      {this.context.ui ? this.context.ui.navDesktop.next : '→'}
                    </span>
                    <span className='az-arrows-small'>
                      {this.context.ui ? this.context.ui.nav.next : '→'}
                    </span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NavbarSideways;
