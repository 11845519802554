import React, { Component } from 'react';
import AppContext from '../../AppContext';

import FilterElement from './FilterElement';
import UIkit from 'uikit';

import { gsap } from 'gsap';

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      modalOpen: false,
      search: '',
      timerOn: false,
    };
    this.slideMenu = React.createRef();
    this.slideBtn = React.createRef();
    this.searchInput = React.createRef();
    this.navbarTop = document.getElementById('navbarTop');
  }
  static contextType = AppContext;

  getSearch = (section) => {
    let searchTerms;
    let arrSearch = [];
    let stringSearch;
    if (section === 'azProjects') searchTerms = 'searchProjects';
    if (section === 'azTeachings') searchTerms = 'searchTeachings';

    arrSearch = this.context[searchTerms];
    if (arrSearch.length > 0) stringSearch = arrSearch.join(' ');
    else stringSearch = 'a';
    this.setState({ search: stringSearch });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.handleClick();
  };

  handleClickAnywhere = (e) => {
    // console.log('Target is', e.target);
    let targetId = e.target.getAttribute('id');
    // console.log('TargetId', targetId);
    if (
      targetId === 'projects_wrapper' ||
      targetId === 'projects_tags' ||
      targetId === 'navbarTop' ||
      targetId === 'navbarTop-left' ||
      targetId === 'navbarTop-right'
    ) {
      this.modalShow(false);
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickAnywhere, false);
    this.setState({ isLoaded: true });
    this.setState({ search: this.props.search });

    gsap.from('.az-hash', {
      duration: 0.5,
      x: window.innerWidth > 640 ? 100 : -100,

      opacity: 1,
      delay: 0.2,

      ease: 'power3',
    });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickAnywhere, false);
  }

  modalShow = (status) => {
    this.setState({ modalOpen: status }, () => {
      if (!status) UIkit.offcanvas(this.slideMenu.current).hide();
      else {
        UIkit.offcanvas(this.slideMenu.current).show();

        if (window.innerWidth > 640) this.searchInput.current.focus();
      }
    });
  };

  handleClick = () => {
    this.modalShow(!this.state.modalOpen);
  };

  handleClear = () => {
    this.setState({ search: '' });
    this.context.setSearch('azProjects', '');
  };

  render() {
    const azData = this.context[this.props.section];

    const { ui } = this.context;
    let { dataTags, selectedTags } = azData;

    let filterItems = dataTags.map((item) => {
      return (
        <FilterElement
          key={item.tagName}
          tag={item}
          handleFilter={this.context.handleFilter}
          section={this.props.section}
          isSelected={
            selectedTags.find((tag) => tag === item.tagName) &&
            'az-filter-selected'
          }
        />
      );
    });

    return (
      <>
        <div className='az-filter-btn'>
          <a
            ref={this.slideBtn}
            className={`mobile_menu_bar az-hash mobile_nav ${
              this.state.modalOpen ? 'opened az-fixed' : 'closed'
            }`}
            onClick={this.handleClick}
          >
            <div className='hashicon' id='hashicon-1'>
              <span className='line-hm'></span>
              <span className='line-hm'></span>
              <span className='line-hm'></span>
              <span className='line-hm'></span>
            </div>
          </a>
        </div>

        <div
          ref={this.slideMenu}
          id='offcanvas-nav'
          uk-offcanvas='overlay: false; flip: true ; bg-close: false'
          className='kr-z-nav-menu'
        >
          <div className='uk-offcanvas-bar az-color-muted az-box-shadow-l az-filter-menu-padding px-0 '>
            <div className='az-relative'>
              {this.props.search !== '' && (
                <a
                  className='az-clear-search az-text-color-1'
                  onClick={this.props.handleClear}
                  uk-icon='icon: close; ratio: 0.75'
                ></a>
              )}
              <form type='submit' onSubmit={this.handleSubmit}>
                <input
                  ref={this.searchInput}
                  className='az-search-input'
                  type='search'
                  placeholder={ui && ui.menuFilter.search}
                  value={this.props.search}
                  onChange={this.props.handleChange}
                />
              </form>
            </div>
            <ul className='uk-nav uk-navbar-dropdown-nav '>{filterItems}</ul>
          </div>
        </div>
      </>
    );
  }
}

export default Filter;
